import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import get from 'lodash/get'

import Container from '../Container'
import Link from '../Link'
import Tags from '../Tags'

import colors from '../../assets/js/colors'
import logo from '../../assets/img/logo.png'

const Header = styled.h1`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`

const Logo = styled.img`
  width: 48px;
  margin-right: .5rem;
`

const Excerpt = styled.p`
  color: ${colors.text};
  font-size: .9rem;
  margin-bottom: 2rem;
`

const StyledList = styled.div`
  padding-top: 1rem;
`

const List = ({title, list}) =>
  <StyledList>
    <h2>{title}</h2>
    {list.map(({node}) =>
      <div key={node.fields.slug}>
        <h4><Link underline to={node.fields.slug}>{get(node, 'frontmatter.title') || node.fields.slug}</Link></h4>
        <Excerpt dangerouslySetInnerHTML={{__html: node.excerpt}} />
      </div>
    )}
  </StyledList>

const PageListTemplate = ({examples, clients, tag}) =>
  <Container>

    <Helmet>
      <title>Dot Dot Data Pages</title>
      <meta property='description' content='Choose a Dot Dot Data Page to broadcast on your data device' />
      <link rel='shortcut icon' href='favicon.ico' />
    </Helmet>

    <Header><Logo src={logo} alt='dot dot data logo' /><span>Dot Dot Data Pages</span></Header>

    <Tags tag={tag} />

    {examples.length > 0 && <List title='Examples' list={examples} />}
    {clients.length > 0 && <List title='Clients' list={clients} />}

  </Container>

export default PageListTemplate