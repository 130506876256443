import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import filter from 'lodash/filter'

import PageListTemplate from '../components/templates/PageListTemplate'

const Index = ({data}) => {
  const pages = get(data, 'allMarkdownRemark.edges')
  const examples = filter(pages, page => !page.node.frontmatter.client)
  const clients = filter(pages, page => page.node.frontmatter.client)

  return (
    <PageListTemplate
      examples={examples}
      clients={clients}
    />
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            client
            tags
          }
        }
      }
    }
  }
`