import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import colors from '../assets/js/colors'

export default styled(Link)`
  color: ${props => props.color || colors.text};
  text-decoration: none;
  transition: all .15s ease-in;
  ${props => props.underline && `border-bottom: 1px dotted ${colors.text}`};
  
  &:focus {
    outline: 1px dotted ${colors.text};
  }
`.withComponent(({underline, color, ...others}) => <Link {...others} />)