import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import styled from 'styled-components'

import Link from './Link'

import colors from '../assets/js/colors'

const List = styled.ul`
  margin: 0;
  padding: 2rem 0 0 0;
  
  li {
    display: inline-block;
    margin: 0 .5rem 1rem 0;
  }
`

const Item = styled(Link)`
  padding: .5rem 1rem;
  background-color: ${props => props.highlight ? colors.primary : ''};
  border-radius: 50px;
`.withComponent(({highlight, ...others}) => <Link {...others} />)

const Clear = styled.div`
  padding-top: 1rem;
`

const Render = ({allMarkdownRemark, tag}) =>
  <List>
    {get(allMarkdownRemark, 'group', []).map(t =>
      <li key={t.fieldValue}>
        <Item
          highlight={tag === t.fieldValue}
          to={tag === t.fieldValue ? '/' : `/${kebabCase(t.fieldValue)}/`}
          color={tag === t.fieldValue ? 'white' : ''}>
          {t.fieldValue} ({t.totalCount})
        </Item>
      </li>
    )}
    {tag && <Clear><Link underline to={`/`}>Clear filters</Link></Clear>}
  </List>

export default ({tag}) =>
<StaticQuery
  query={graphql`
  query TagsComponentQuery {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }`}
  render={others => <Render tag={tag} {...others} />}
/>